<template>
<div>
  Email Settings
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EmailSettings',
  computed: mapState({

  }),
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
